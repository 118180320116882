import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-hrt.jpg'

class HRCMontclair extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query HRCMontclairMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Hormone Replacement Therapy Montclair, NJ | ${data.site.siteMetadata.title}`} description={`Testosterone replacement therapy, performed here in Montclair, is a proven solution for those that experience discomfort, imbalance, or other issues due to the decline of testosterone production.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Hormone Replacement Therapy Montclair, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>As men age, testosterone is lowered due to the natural decline of the capabilities of our reproductive organs. Testosterone replacement therapy, performed here in Montclair, is a proven solution for those that experience discomfort, imbalance, or other issues due to the decline of testosterone production. Also called hormone replacement therapy, testosterone replacement can help improve quality of life by restoring testosterone back to natural and normal levels.</p>

                                    <p>Symptoms of low testosterone can include:</p>

                                    <ul>
                                        <li>weight gain</li>
                                        <li>weight retention</li>
                                        <li>excessive fatigue</li>
                                        <li>difficulty gaining muscle</li>
                                        <li>sexual dysfunction</li>
                                        <li>loss of libido</li>
                                        <li>frequent mood changes</li>
                                        <li>sleeping difficulties</li>
                                        <li>…and plenty more</li>
                                    </ul>

                                    <p>Testosterone replacement therapy with Optimal health and Wellness in Montclair can help get testosterone and hormones back to appropriate levels, and eradicate symptoms and struggles that come with low hormone function. Low testosterone and hormones can affect many areas of our lives, because hormone receptors can be found all throughout our bodies. Hormone replacement helps to increase quality of life by bringing your body back to a proper balance.</p>

                                    <p>Age-related testosterone decline is more prevalent today than any other time in history, and more and more men are being affected at younger ages. At Optimal Health and Wellness, Androgen Society member Dr. Sambataro stays up to date on all relevant testosterone and testosterone therapy research, applying any developments into his Montclair practices.</p>

                                    <p>If you feel that testosterone replacement therapy could be a solution to your testosterone issues, reach out today and schedule a consultation to see if you are a good candidate.</p>

                                    <h2>Hormone Replacement Therapy &mdash; What is it?</h2>

                                    <p>There are a variety of different options when it comes to hormone replacement therapy. The 3 most common methods are:</p>

                                    <ul>
                                        <li><em>Topical Gel: this is probably the most common method for testosterone replacement therapy, and involves a gel applied to the arms, shoulders, or abdomen.</em></li>
                                        <li><em>Testosterone Injection: injections are administered by a doctor every 2-4 weeks, depending on the severity of the testosterone condition being treated.</em></li>
                                        <li><em>Testosterone Patches: a testosterone patch is placed on the skin daily, allowing testosterone to enter the bloodstream.</em></li>
                                    </ul>

                                    <p>Each of these methods help testosterone to enter the body and raise levels up to levels experienced before the onset of your hormonal decline.</p>

                                    <p>Your Montclair doctor will help determine which method is most effective for your individual situation. We will also go over each type of method and the side effects that may occur in each situation.</p>

                                    <h2>Hormonal Replacement Therapy &mdash; Side Effects</h2>

                                    <p>As with any medical procedure, therapy, surgery, etc, there are risks that come with testosterone replacement. Risks are significantly minimized by a reputable doctor, such as Dr. Sambataro in Montclair. However, underlying medical conditions can also play a factor in the risks of hormone replacement. Therefore, it is vital that you share all medical issues in your consultation, to ensure that no underlying medical conditions will lead to increased risk of side effects.</p>

                                    <p>Potential side effects include:</p>

                                    <ul>
                                        <li>acne</li>
                                        <li>breast enlargement</li>
                                        <li>decreased sperm count</li>
                                        <li>decreased testicle size</li>
                                        <li>fluid retention</li>
                                        <li>high blood pressure</li>
                                        <li>infertility</li>
                                        <li>increased red blood cells</li>
                                    </ul>

                                    <p>Most individuals do not encounter significant side effects, and instead are presented with a new quality of life improvement with normal testosterone levels. Again, it is important to be open, honest, and transparent in your consultation to decrease the risk of side effects even further.</p>

                                    <h2>Hormone Replacement Therapy &mdash; Consultation</h2>

                                    <p>The first step for testosterone replacement is a consultation with an expert here in Montclair. We will discuss the benefits and risk of hormone therapy, determine candidacy, go over medical history, and answer any other questions or concerns you may have. <a href={`tel:${data.site.siteMetadata.phone}`} rel='noopener noreferrer'>call today</a>!</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default HRCMontclair